var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.form_loading,
          expression: "form_loading"
        }
      ],
      staticClass: "login-container"
    },
    [
      _c("div", { staticClass: "login-box" }, [
        _vm.action_step == "EMAIL_INPUT" || _vm.action_step == "PASSWORD_INPUT"
          ? _c(
              "div",
              { staticClass: "login-container-input" },
              [
                _c("div", { staticClass: "logo-container" }, [
                  _c("img", {
                    attrs: { src: _vm.publicPath + "img/logo-black.png" }
                  })
                ]),
                _c("div", { staticClass: "page-title" }, [
                  _c("h3", [_vm._v("Log In")]),
                  _c(
                    "p",
                    {
                      style: {
                        color:
                          _vm.feedback_message.type == "error"
                            ? "#e81123"
                            : "inherit"
                      }
                    },
                    [_vm._v(_vm._s(_vm.feedback_message.message))]
                  )
                ]),
                _c("el-input", {
                  attrs: { autofocus: "", placeholder: "Email" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submit_login($event)
                    }
                  },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                }),
                _vm.action_step == "PASSWORD_INPUT"
                  ? _c("el-input", {
                      ref: "password_input",
                      attrs: { placeholder: "Password", "show-password": "" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.submit_login($event)
                        }
                      },
                      model: {
                        value: _vm.password,
                        callback: function($$v) {
                          _vm.password = $$v
                        },
                        expression: "password"
                      }
                    })
                  : _vm._e(),
                _c(
                  "p",
                  [
                    _vm._v("No account? "),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to:
                            "/register-carpooling?company=DublinCityUniversity"
                        }
                      },
                      [_vm._v("Create one!")]
                    )
                  ],
                  1
                ),
                _c(
                  "p",
                  { staticStyle: { "margin-top": "5px" } },
                  [
                    _c("router-link", { attrs: { to: "/forgot-password" } }, [
                      _vm._v("Reset password.")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "action-button" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "secondary-color",
                        attrs: { type: "primary" },
                        on: { click: _vm.submit_login }
                      },
                      [_vm._v(_vm._s(_vm.submit_text))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.action_step == "PASSWORDLESS_WAITING"
          ? _c("div", { staticClass: "login-container-waiting" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "start-icon",
                    staticStyle: { padding: "30px" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.publicPath + "img/email-check.png",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "24px",
                    "text-align": "center",
                    margin: "20px 0 10px 0"
                  }
                },
                [_vm._v("Check your email!")]
              ),
              _c(
                "span",
                { staticStyle: { display: "block", "text-align": "center" } },
                [
                  _vm._v("We have sent you an email to "),
                  _c("b", [_vm._v(_vm._s(this.email))]),
                  _vm._v(
                    ". Check your email and click on the confirmation link to continue."
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm.action_step == "EMAIL_VERIFIED"
          ? _c("div", { staticClass: "login-container-waiting" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "start-icon",
                    staticStyle: { padding: "30px" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.publicPath + "img/email-check.png",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "24px",
                    "text-align": "center",
                    margin: "20px 0 10px 0"
                  }
                },
                [_vm._v("Your email is verified!")]
              ),
              _c(
                "span",
                { staticStyle: { display: "block", "text-align": "center" } },
                [
                  _vm._v(
                    "We have verified your email successfully. Now you can download the app and start enjoying Sharo!"
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "margin-top": "20px",
                    "text-align": "center"
                  }
                },
                [
                  !_vm.isPWA()
                    ? _c(
                        "el-button",
                        {
                          staticClass: "secondary-color",
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/download")
                            }
                          }
                        },
                        [_vm._v("Download app")]
                      )
                    : _c(
                        "el-button",
                        {
                          staticClass: "secondary-color",
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.action_step = "EMAIL_INPUT"
                            }
                          }
                        },
                        [_vm._v("Login")]
                      )
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }