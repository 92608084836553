<template>
  <div v-loading="form_loading" class="login-container">
    <div class="login-box">
      <!-- Show unless showing message of waiting for email link -->
      <div v-if="action_step == 'EMAIL_INPUT' || action_step == 'PASSWORD_INPUT'" class="login-container-input">
        <div class="logo-container">
          <img :src="`${publicPath}img/logo-black.png`">
        </div>

        <div class="page-title">
          <h3>Log In</h3>
          <p :style="{ color: feedback_message.type == 'error' ? '#e81123' : 'inherit' }">{{ feedback_message.message }}</p>
        </div>

        <el-input autofocus @keyup.native.enter="submit_login" placeholder="Email" v-model="email"></el-input>

        <el-input @keyup.native.enter="submit_login" ref="password_input" v-if="action_step == 'PASSWORD_INPUT'" placeholder="Password" v-model="password" show-password></el-input>

        <p>No account? <router-link to="/register-carpooling?company=DublinCityUniversity">Create one!</router-link></p>
        <p style="margin-top: 5px"><router-link to="/forgot-password">Reset password.</router-link></p>

        <div class="action-button">
          <el-button
            class="secondary-color"
            type="primary"
            @click="submit_login"
          >{{ submit_text }}</el-button>
        </div>
      </div>

      <!-- Show only if waiting for email link -->
      <div v-if="action_step == 'PASSWORDLESS_WAITING'" class="login-container-waiting">
        <div class="start-icon-container">
					<div class="start-icon" style="padding: 30px">
						<img :src="`${publicPath}img/email-check.png`" alt="">
					</div>
				</div>

        <span style="display: block; font-size: 24px; text-align: center; margin: 20px 0 10px 0;">Check your email!</span>
				<span style="display: block; text-align: center">We have sent you an email to <b>{{ this.email }}</b>. Check your email and click on the confirmation link to continue.</span>
      </div>


      <!-- Show only if waiting for email link -->
      <div v-if="action_step == 'EMAIL_VERIFIED'" class="login-container-waiting">
        <div class="start-icon-container">
					<div class="start-icon" style="padding: 30px">
						<img :src="`${publicPath}img/email-check.png`" alt="">
					</div>
				</div>

        <span style="display: block; font-size: 24px; text-align: center; margin: 20px 0 10px 0;">Your email is verified!</span>
				<span style="display: block; text-align: center">We have verified your email successfully. Now you can download the app and start enjoying Sharo!</span>

        <div style="width: 100%; margin-top: 20px; text-align: center;">
          <el-button v-if="!isPWA()" type="primary" class="secondary-color" @click="$router.push('/download')">Download app</el-button>
          <el-button v-else type="primary" class="secondary-color" @click="action_step = 'EMAIL_INPUT'">Login</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import firebase from '@/firebase';

export default {
  data: () => ({
    email: '',
    password: '',
    feedback_message: {
      type: 'info',
      message: "You need to login with your organization's email and Sharo's password.",
    },
    form_loading: false,
    submit_text: 'Next',
    action_step: 'EMAIL_INPUT' /* EMAIL_INPUT, PASSWORDLESS_WAITING, PASSWORD_INPUT, EMAIL_VERIFIED */,
    publicPath: process.env.BASE_URL
  }),


  methods: {
    submit_login() {
      this.form_loading = true;
      this.email = this.email.trim();

      // If they just put their email
      if (this.action_step == 'EMAIL_INPUT') {
        firebase.auth().fetchSignInMethodsForEmail(this.email).then(result => {

          if (result.length < 1) {
            this.feedback_message.type = 'error';
            this.feedback_message.message = "That account doesn't exist. Enter a different email or get a new one.";
            this.form_loading = false;
          } else {
            this.feedback_message.type = 'info';
            this.feedback_message.message = "You need to login with your organization's email and Sharo's password.";
            // Password login
            if (result.includes('password')) {
              this.action_step = 'PASSWORD_INPUT';
              this.submit_text = 'Login';
              this.form_loading = false;
              setTimeout(_ => this.$refs.password_input.$el.children[0].focus(), 200);

            // Passwordless login
            } else if (result.includes('emailLink')) {
              // Send confirmation email
              this.SharoAPI({ action: "userAuthHelper", data: { email: this.email, type: "generateSignInWithEmailLink", url: window.location.origin }}).then(result => {
                if (result.data.error) {
                  this.feedback_message.type = 'error';
                  this.feedback_message.message = result.data.error_message;
                }

                localStorage.setItem("emailForSignIn", this.email);
                this.action_step = 'PASSWORDLESS_WAITING';
                this.form_loading = false;

              }).catch(_ => this.form_loading = false);
            }
          }
        }).catch(err => {
          this.feedback_message.type = 'error';
          this.feedback_message.message = err.message;
          this.form_loading = false;
        });

        return;
      } else if (this.action_step == 'PASSWORD_INPUT') {
        this.$store
        .dispatch('login', { email: this.email, password: this.password })
        .then(result => {
          this.form_loading = false;

          this.$router.push(this.$route.query.redirectUrl || '/').catch(() => {});
        })
        .catch(err => {
          console.log(err);
          setTimeout(_ => {
            this.form_loading = false;
            this.feedback_message.type = 'error';
            this.feedback_message.message = "The password is invalid, try again or reset your password.";
          }, 600);
        });
      }
    }
  },

  async mounted() {
    this.email = this.$route.query.email || '';
    
    if (['EMAIL_INPUT', 'PASSWORDLESS_WAITING', 'PASSWORD_INPUT', 'EMAIL_VERIFIED'].includes(this.$route.query.action_step)) {
      this.action_step = this.$route.query.action_step;
    }
  }
}
</script>





<style lang="scss" scoped>
@import '@/theme/variables.scss';

.logo-container {
  width: 100%;
  opacity: 0.8;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  img { width: 120px; }
}

.start-icon-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;

  .start-icon {
    width: 150px;
    height: 150px;
    padding: 20px;
    // border: 1px solid blue;
    background-color: #d1f1ff;
    border-radius: 50%;

    img {
      width: 100%;
      opacity: 0.9;
    }
  }
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 69px);
}

.login-box {
  padding: 15px;
  max-width: 500px;
  // border: 1px solid blue;
  width: 100%;
}

.el-input {
  margin-bottom: 15px;
}

.action-button {
  width: 100%;
  text-align: right;
}
</style>
